import { zodResolver } from "@hookform/resolvers/zod";
import {
  kikCustomerSignUpWithActionCodeFieldsSchema,
  KikUserZodSchemas,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { z } from "zod";

import richTextStyle from "~/components/common/rich-text.module.scss";
import Errors from "~/components/form/errors";
import { useActionCodeContext } from "~/contexts/action-code";
import { useAuthContext } from "~/contexts/auth";
import { useFormTranslations } from "~/hooks/use-form-translations";
import Logger from "~/services/logger/logger";

import InputField from "../form/input-field";
import styles from "../sign-up/dialog-sign-up-double-opt-in.module.scss";
import Button from "./button";
import Dialog from "./dialog";
import Icon from "./icon";

type Props = {
  onSubmit?: (data: string) => Promise<void>;
  onSendAnother?: () => Promise<void>;
};

export default function ActionCodeDialog(props: Props) {
  const t = useTranslations();
  const {
    timeLeft,
    dialogOpenState: [isActionCodeDialogOpen, setIsActionCodeDialogOpen],
  } = useActionCodeContext();
  const { getFieldError } = useFormTranslations();

  const actionCodeSchema = kikCustomerSignUpWithActionCodeFieldsSchema.innerType().pick({
    actionCode: !0,
  });

  type ValidationSchema = z.infer<typeof actionCodeSchema>;

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ValidationSchema>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
    resolver: zodResolver(actionCodeSchema),
  });
  const { isLoading, authError } = useAuthContext();

  const onSubmit = (data: ValidationSchema) => {
    props
      .onSubmit?.(data.actionCode)
      .then(() => {
        reset();
      })
      .catch((e) => {
        Logger.instance.warn("ActionCodeDialog: ", e);
      });
  };

  const sendAnother = () => {
    Logger.instance.debug(`ActionCodeDialog: trying to send another with time left = ${timeLeft}...`);
    reset();
    props.onSendAnother?.();
  };

  return (
    <Dialog
      isOpen={isActionCodeDialogOpen}
      title={
        <>
          <Icon width={20} height={20} name="secure-payment" className={styles.icon} />
          {t("generic.confirmationCode")}
        </>
      }
      customStyles={styles}
      onClose={() => setIsActionCodeDialogOpen(!1)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <p className={styles.doubleOptInTitle}>{t("generic.signUpDoubleOptInTitle")}</p>
        <p className={styles.doubleOptInDescription}>
          {t.rich("generic.signUpDoubleOptInDesc", {
            br: () => <br />,
          })}
        </p>
        <InputField
          type="text"
          label={t("generic.form.action_code")}
          error={getFieldError(errors.actionCode?.message, {
            fieldName: t("generic.form.action_code"),
            minLength: KikUserZodSchemas.actionCodeLength,
          })}
          {...register("actionCode")}
          isSubmitting={isSubmitting}
          maxLength={KikUserZodSchemas.actionCodeLength}
          onClearErrors={() => clearErrors("actionCode")}
          className={styles.input}
        />
        <Button
          className={styles.submit}
          disabled={isLoading || isSubmitting}
          loading={isLoading || isSubmitting}
          variant="primary"
          type="submit"
          value="submit"
        >
          {t("generic.confirmMyIdentity")}
        </Button>
        {timeLeft ? (
          <p className={richTextStyle.paragraph}>
            {t.rich("generic.signUpDoubleOptInSendAnotherIn", {
              seconds: timeLeft,
            })}
          </p>
        ) : (
          <p className={richTextStyle.paragraph}>
            {t.rich("generic.signUpDoubleOptInActionCodeNotReceived", {
              sendAnother: (chunks) => (
                <Button className={richTextStyle.link} lookAsLink disabled={isLoading} onClick={() => sendAnother()}>
                  {chunks}
                </Button>
              ),
            })}
          </p>
        )}
      </form>
      <Errors error={authError}></Errors>
    </Dialog>
  );
}
