import { useCallback, useEffect, useState } from "react";

interface UseTimerProps {
  initialTime?: number;
  autostart?: boolean;
  autoreset?: boolean;
}

const DEFAULT_TIME = 60;

/**
 * A util to manage a countdown timer.
 * @param initialTime the amount of seconds you want to count
 * @param autostart set true to start counting as soon as you use the hook. Use resetTimer to start otherwise.
 * @param autoreset set true to loop automatically when time is out
 */
export const useTimer = ({ initialTime = DEFAULT_TIME, autostart = false, autoreset = false }: UseTimerProps = {}) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(autostart ? initialTime : null);

  const resetTimer = useCallback(() => {
    setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (timeLeft === null) return;

    if (timeLeft === 0) {
      if (autoreset) {
        return resetTimer();
      }
      setTimeLeft(null);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime !== null ? prevTime - 1 : null));
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return { timeLeft, resetTimer };
};

export default useTimer;
