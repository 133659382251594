import { zodResolver } from "@hookform/resolvers/zod";
import {
  KikMarketingCloudDoubleOptInProcedures,
  KikProspectSignUpWithActionCodeFields,
  kikProspectSignUpWithActionCodeFieldsSchema,
  KikUserZodSchemas as ZodSchemas,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import z from "zod";

import ActionCodeDialog from "~/components/common/action-code-dialog";
import NewsletterTypewriter from "~/components/common/newsletter-typewriter";
import Checkbox from "~/components/form/checkbox";
import Errors from "~/components/form/errors";
import InputField from "~/components/form/input-field";
import { useActionCodeContext } from "~/contexts/action-code";
import { ActionCodeContextProvider } from "~/contexts/action-code-context-provider";
import { useAuthContext } from "~/contexts/auth";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useGlobalNotification } from "~/hooks/use-global-notification";
import Logger from "~/services/logger/logger";
import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/cross-country-utils";

import EditorialLink from "../editorials/editorial-link/editorial-link";
import Button from "./button";
import EscapeNewLine from "./escape-new-line";
import styles from "./newsletter-box.module.scss";

type Props = {
  entry: Entry<Editorials.Footer>;
};

/**
 * New version of the inner component, using the action code double opt-in
 */
function NewsletterBoxInnerActionCode({ entry }: Props) {
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const t = useTranslations();
  const formRef = useRef<HTMLFormElement>(null);
  // Form doesn't actually include the action code, as it's managed via dedicated dialog
  const formSchema = kikProspectSignUpWithActionCodeFieldsSchema.omit({
    actionCode: !0,
    CountryID: !0,
    LanguageID: !0,
  });
  type FormData = z.infer<typeof formSchema>;

  const { signUpProspectWithActionCode, sendEmailVerification, isLoading, authError } = useAuthContext();
  const {
    resetTimer,
    timeLeft,
    dialogOpenState: [, setIsActionCodeDialogOpen],
  } = useActionCodeContext();
  const { newSuccessGlobalNotification } = useGlobalNotification();
  const currentCountry = crossCountryUtils.getCurrentCountryCode(router);
  const currentLanguage = crossCountryUtils.getCurrentLanguageCode(router);
  const formMethods = useForm<FormData>({
    ...Constants.USE_FORM_VALIDATION_DEFAULTS,
    resolver: zodResolver(formSchema),
  });
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    control,
    formState: { errors, isSubmitted },
  } = formMethods;

  const doSendVerification = (data: FormData = formMethods.getValues()) => {
    Logger.instance.info(`NewsletterBox: prospect request ${data.EmailAddress}`, data);
    // Re-validate fields
    const parsed = formSchema.safeParse(data);

    if (!parsed.success) {
      return Promise.resolve();
    }

    if ((timeLeft || 0) > 0) {
      setIsActionCodeDialogOpen(!0);
      return Promise.resolve();
    }

    return sendEmailVerification({
      ...parsed.data,
      CountryID: currentCountry,
      LanguageID: currentLanguage,
      procedure: KikMarketingCloudDoubleOptInProcedures.SIGN_UP_PROSPECT,
    })
      .then(() => {
        resetTimer();
        setIsActionCodeDialogOpen(!0);
      })
      .catch((e) => {
        setIsActionCodeDialogOpen(!1);
        Logger.instance.error("NewsletterBox: could not call sendEmailVerification", e);
      });
  };

  /**
   * Finalize the sign-up after entering the action code
   * @param actionCode
   */
  const doSignUpWithActionCode = (actionCode: string) => {
    const requestData: KikProspectSignUpWithActionCodeFields = {
      ...formMethods.getValues(),
      CountryID: currentCountry,
      LanguageID: currentLanguage,
      actionCode,
    };
    Logger.instance.info(`NewsletterBox: prospect completion ${requestData.EmailAddress}`, requestData);

    return signUpProspectWithActionCode(requestData)
      .then(() => {
        setIsActionCodeDialogOpen(!1);
        newSuccessGlobalNotification(
          {
            slug: "newsletterBoxConfirmation",
            closeOnClickCta: true,
            ctaCaption: "generic.ok",
          },
          {
            timeout: 10000,
          }
        );
        reset();
      })
      .catch((err) => {
        Logger.instance.error("NewsletterBox: sign-up with action code errored", {
          err,
          authError,
        });
      });
  };

  const onSubmit = (data: FormData) => {
    doSendVerification(data);
  };

  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  const { title, titleAnimated, subtitle, highlightedText, linkPrivacyPolicy } = entry.fields;

  useEffect(() => {
    if (isSubmitted && authError) {
      setError("root.serverError", {
        type: `${authError.errorCode}`,
      });
    }
  }, [isSubmitted, authError, setError]);

  return (
    <div className={styles.titleAndNewsletterContainer}>
      <div className={styles.wrapperTitle}>
        <h2>
          <NewsletterTypewriter
            entry={entry}
            initialText={title}
            texts={titleAnimated}
            typingDelay={80}
            deletingDelay={50}
            pauseDelay={4000}
          />
        </h2>
        <h2>
          <p className={styles.subtitle} {...inspectorMode?.getProps("subtitle")}>
            <EscapeNewLine text={subtitle} />
          </p>
          {highlightedText ? (
            <p className={styles.highlightedText} {...inspectorMode?.getProps("highlightedText")}>
              <EscapeNewLine text={highlightedText} />
            </p>
          ) : null}
        </h2>
      </div>
      <FormProvider {...formMethods}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} tabIndex={-1} noValidate>
          <InputField
            type="email"
            label={t("generic.form.insert_email_label")}
            isOptional
            error={
              errors.EmailAddress?.message
                ? t(errors.EmailAddress?.message, {
                    fieldName: t("generic.email"),
                    maxLength: ZodSchemas.emailMaxlength,
                  })
                : undefined
            }
            {...register("EmailAddress")}
            isSubmitting={isLoading}
            onClearErrors={() => clearErrors("EmailAddress")}
            className={styles.newsletterInput}
          />
          <Errors
            error={isSubmitted ? authError : null}
            style={{ gridColumn: breakpoint === "desktop" ? "2 span" : "1" }}
          />
          <Controller
            name={"MarketingOptIn"}
            render={({ field }) => {
              return (
                <Checkbox
                  {...register("MarketingOptIn")}
                  checked={!!field.value}
                  className={styles.newsletterCheckbox}
                  label={t("generic.footerPrivacyDisclaimer")}
                  error={
                    errors.MarketingOptIn?.message
                      ? t("error.fieldRequired", { fieldName: t("generic.thisField") })
                      : void 0
                  }
                />
              );
            }}
            control={control}
          />
          <EditorialLink entry={linkPrivacyPolicy} className={styles.link} />
          <Button
            disabled={isLoading}
            loading={isLoading}
            className={styles.submitField}
            variant="secondary"
            type="submit"
          >
            {t("generic.newsletterSignUp")}
          </Button>
          {/*<Button className={styles.submitField} variant="secondary" type="button" onClick={() => reset()}>*/}
          {/*  RESET*/}
          {/*</Button>*/}
        </form>
        <ActionCodeDialog onSubmit={doSignUpWithActionCode} onSendAnother={doSendVerification} />
      </FormProvider>
    </div>
  );
}

export default function NewsletterBox(props: Props) {
  return (
    <ActionCodeContextProvider>
      <NewsletterBoxInnerActionCode {...props} />
    </ActionCodeContextProvider>
  );
}
